import { useState, useEffect, useContext } from "react";
import { BaseStateContext } from "../App";
import axios from "axios";
import xml2js from "xml2js";
import { ServerConfigResponse, MainConfig } from "../types";
import { ensure } from "../helpers";

function useGVCoreConfig() {
  const { baseState, updateBaseState } = useContext(BaseStateContext);
  const [configuration, setConfiguration] = useState<MainConfig>(defaultConfig);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    if (baseState.isLoggedIn) {
      setIsLoading(true);
      axios
        .get(
          `${baseState.baseURL}${
            baseState.parent
          }/GVCore.xml?timestamp=${new Date().getTime()}`
        )
        .then((response) =>
          xml2js
            .parseStringPromise(response.data)
            .then((response) => response.dataroot)
        )
        // Load user configuration
        .then((response: ServerConfigResponse) => {
          let modifiedResponse: ServerConfigResponse = JSON.parse(
            JSON.stringify(response)
          );
          // Add missing attributes for parent
          attributes.forEach((attribute) => {
            if (!Object.keys(response).includes(attribute)) {
              modifiedResponse[attribute] = ["0"];
            }
            return true;
          });
          // Add missing attributes for childs
          if (response.kind) {
            (modifiedResponse.kind || []).forEach((kind) => {
              attributes.forEach((attribute) => {
                if (!Object.keys(kind).includes(attribute)) {
                  kind[attribute] = modifiedResponse[attribute];
                }
              });
              // Same stuff for grandchilds
              if (kind.enkel) {
                kind.enkel.forEach((enkel) => {
                  attributes.forEach((attribute) => {
                    if (!Object.keys(enkel).includes(attribute)) {
                      enkel[attribute] = kind[attribute];
                    }
                  });
                });
              }
            });
          }
          return modifiedResponse;
        })
        // Set all states according to user configuration
        .then((response: ServerConfigResponse) => {
          let config: ServerConfigResponse | undefined;
          let orderingUserClass =
            baseState.orderingUser.substr(2, 3) === "000"
              ? "parent"
              : baseState.orderingUser.length === 5
              ? "child"
              : "grandchild";
          switch (orderingUserClass) {
            case "parent":
              config = response;
              break;
            case "child":
              config = (response.kind || []).find(
                (kind) => kind.id_benutzer[0] === baseState.orderingUser
              );
              break;
            case "grandchild":
              let kind = (response.kind || []).find(
                (kind) =>
                  kind.id_benutzer[0] === baseState.orderingUser.substr(0, 5)
              );
              if (kind) {
                config = (kind.enkel || []).find(
                  (enkel) =>
                    enkel.id_benutzer[0] === `${baseState.orderingUser}`
                );
              }
              break;
            default:
              break;
          }

          if (config) {
            setConfiguration({
              appGVCountActive: config.appGVCountActive[0] === "1",
              appMenuActive: config.appMenuActive[0] === "1" || false,
              appConfigActive: config.appConfigActive[0] === "1",
              appSupermarketActive: config.appSupermarketActive[0] === "1",
              appPastOrdersActive: config.appPastOrdersActive[0] === "1",
              logoMainVisible: config.logoMainVisible[0] === "1",
              logoGVCVisible: config.logoGVCVisible[0] === "1",
              logoParentVisible: config.logoParentVisible[0] === "1",
              logoChildVisible: config.logoChildVisible[0] === "1",
              backgroundColor:
                config.backgroundColor[0] === "0"
                  ? ""
                  : config.backgroundColor[0],
              titleBackgroundColor: config.titleBackgroundColor[0],
              serviceContactHTML:
                config.serviceContactHTML[0] === "0"
                  ? ""
                  : config.serviceContactHTML[0],
              titleFontColor: config.titleFontColor[0] || "",
              titleFontSize: config.titleFontSize[0],
              docsGVCareVisible: config.docsGVCareVisible[0] === "1",
              docsGVConceptVisible: config.docsGVConceptVisible[0] === "1",
              docsGVConditionVisible: config.docsGVConditionVisible[0] === "1",
              pdfPlanTyp: config.pdfPlanTyp[0],
              customerStringType: parseInt(config.customerStringType[0]) || 1,
              appDocsActive: config.appDocsActive[0] === "1",
              customerName: config.customerName[0],
            });
            setIsLoading(false);
          }

          if (config) {
            let tmpUsers = [];
            tmpUsers.push({
              id: config.id_benutzer[0],
              name: (config.customerName || ["Benutzer"])[0],
            });
            if (config.kind) {
              config.kind.forEach((child: any) => {
                tmpUsers.push({
                  id: child.id_benutzer[0],
                  name: (child.customerName || ["Benutzer"])[0],
                });
                if (child.enkel) {
                  child.enkel.forEach((grandchild: any) => {
                    tmpUsers.push({
                      id: grandchild.id_benutzer[0],
                      name: (grandchild.customerName || ["Benutzer"])[0],
                    });
                  });
                }
              });
            }
            updateBaseState({
              type: "UPDATEUSERNAMES",
              userNames: tmpUsers,
            });
            updateBaseState({
              type: "CHANGEORDERINGUSER",
              orderingUser: baseState.orderingUser,
              orderingUserClass: baseState.orderingUserClass,
              orderingUserName: ensure(
                tmpUsers.find((user) => user.id === baseState.orderingUser)
              ).name,
            });
            setIsLoading(false);
          }
        });
    }
  }, [
    baseState.orderingUser,
    baseState.loggedInUser,
    baseState.isLoggedIn,
    baseState.baseURL,
    baseState.parent,
  ]);

  return [configuration, setConfiguration, isLoading] as const;
}

const attributes = [
  "appGVCountActive",
  "appMenuActive",
  "appSupermarketActive",
  "appDocsActive",
  "appConfigActive",
  "appPastOrdersActive",
  "logoMainVisible",
  "logoGVCVisible",
  "logoParentVisible",
  "logoChildVisible",
  "backgroundColor",
  "titleFontSize",
  "titleBackgroundColor",
  "titleFontColor",
  "serviceContactHTML",
  "docsGVCareVisible",
  "docsGVConceptVisible",
  "docsGVConditionVisible",
  "pdfPlanTyp",
  "customerStringType",
  "customerName",
];

const defaultConfig: MainConfig = {
  appGVCountActive: false,
  appMenuActive: false,
  appSupermarketActive: false,
  appDocsActive: false,
  appPastOrdersActive: false,
  logoMainVisible: false,
  logoGVCVisible: false,
  logoParentVisible: false,
  logoChildVisible: false,
  backgroundColor: "#F1F2F6",
  titleBackgroundColor: "white",
  serviceContactHTML: "",
  titleFontColor: "",
  titleFontSize: "",
  docsGVCareVisible: false,
  docsGVConceptVisible: false,
  docsGVConditionVisible: false,
  pdfPlanTyp: "",
  customerStringType: 1,
  appConfigActive: false,
  customerName: "",
};

export default useGVCoreConfig;
