// React
import React, { useReducer, Reducer, FC } from "react";
import { BrowserRouter as Router } from "react-router-dom";

// Components
import Login from "./components/login";
import GVCore from "./gvcore";

// Types
import { User } from "./types";

// Main App Component
// ==================
//
// The purpose of this component is to
// provide the basic context to the
// whole application

const App: FC = () => {
  const [baseState, updateBaseState] = useReducer(
    baseStateReducer,
    initialBaseState
  );

  return (
    <BaseStateContext.Provider
      value={{
        baseState,
        updateBaseState,
      }}
    >
      <Router>{baseState.isLoggedIn ? <GVCore /> : <Login />}</Router>
    </BaseStateContext.Provider>
  );
};

export default App;

type BaseState = {
  loggedInUser: string;
  orderingUser: string;
  orderingUserName: string;
  loggedInUserClass: "" | "parent" | "child" | "grandchild";
  orderingUserClass: "" | "parent" | "child" | "grandchild";
  parent: string;
  baseURL: string;
  gvcoreConfigUrl: string;
  authfactor: string;
  isLoggedIn: boolean;
  isAdmin: boolean;
  dbname: string;
  userNames: User[];
  key: string;
  tan: string;
  permCanCreateTan: boolean;
  server: string;
  isLoading: boolean;
};

type BaseContext = {
  baseState: BaseState;
  updateBaseState: any;
};

type BaseStateAction =
  | {
      type: "LOGIN";
      loggedInUser: string;
      orderingUser: string;
      orderingUserName: string;
      isLoggedIn: boolean;
      isAdmin: boolean;
      parent: string;
      baseURL: string;
      gvcoreConfigUrl: string;
      authfactor: string;
      loggedInUserClass: "parent" | "child" | "grandchild";
      orderingUserClass: "parent" | "child" | "grandchild";
      dbname: string;
      tan: string;
      permCanCreateTan: boolean;
    }
  | { type: "LOGOUT" }
  | { type: "UPDATEUSERNAMES"; userNames: User[] }
  | {
      type: "CHANGEORDERINGUSER";
      orderingUser: string;
      orderingUserClass: "parent" | "child" | "grandchild";
      orderingUserName: string;
    }
  | {
      type: "UPDATELOGGEDINUSERCLASS";
      loggedInUserClass: "parent" | "child" | "grandchild";
    }
  | {
      type: "UPDATEORDERINGUSERCLASS";
      orderingUserClass: "parent" | "child" | "grandchild";
    }
  | { type: "SETISLOADING"; isLoading: boolean };

const initialBaseState: BaseState = {
  loggedInUser: "0",
  orderingUser: "0",
  orderingUserName: "",
  loggedInUserClass: "",
  orderingUserClass: "",
  parent: "00000",
  baseURL: "",
  gvcoreConfigUrl: "",
  authfactor: "",
  isLoggedIn: false,
  isAdmin: false,
  dbname: "",
  userNames: [],
  key: "yDRmLgpxnhBXzokQIrKM",
  tan: "",
  permCanCreateTan: false,
  server: process.env.REACT_APP_SERVER as string,
  isLoading: false,
};

export let BaseStateContext = React.createContext<BaseContext>({
  baseState: initialBaseState,
  updateBaseState: undefined,
});

const baseStateReducer: Reducer<BaseState, BaseStateAction> = (
  state: BaseState,
  action: BaseStateAction
) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        loggedInUser: action.loggedInUser,
        orderingUser: action.orderingUser,
        isLoggedIn: action.isLoggedIn,
        parent: action.parent,
        isAdmin: action.isAdmin,
        loggedInUserClass: action.loggedInUserClass,
        orderingUserClass: action.orderingUserClass,
        baseURL: action.baseURL,
        gvcoreConfigUrl: action.gvcoreConfigUrl,
        authfactor: action.authfactor,
        dbname: action.dbname,
        tan: action.tan,
        permCanCreateTan: action.permCanCreateTan,
      };
    case "LOGOUT":
      return initialBaseState;
    case "UPDATEUSERNAMES":
      return { ...state, userNames: action.userNames };
    case "CHANGEORDERINGUSER":
      return {
        ...state,
        orderingUser: action.orderingUser,
        orderingUserClass: action.orderingUserClass,
        orderingUserName: action.orderingUserName,
      };
    case "UPDATELOGGEDINUSERCLASS":
      return { ...state, loggedInUserClass: action.loggedInUserClass };
    case "UPDATEORDERINGUSERCLASS":
      return { ...state, orderingUserClass: action.orderingUserClass };
    default:
      return state;
    case "SETISLOADING":
      return { ...state, isLoading: action.isLoading };
  }
};
