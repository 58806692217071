import { useState, useEffect, useContext } from "react";
import { BaseStateContext } from "../App";
import axios from "axios";
import { LocalDate } from "@js-joda/core";

function useTypeDocs(type: string, week: number, year: number) {
  const [files, setFiles] = useState<{ filename: string; isNew: boolean }[]>(
    []
  );
  const { baseState } = useContext(BaseStateContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (type) {
      const monday = LocalDate.now().minusDays(
        LocalDate.now().dayOfWeek().value() - 1
      );
      setLoading(true);
      axios
        .post(
          `${baseState.baseURL}${baseState.parent}/Internet/listPdfFilesWithDate.php`,
          {
            key: baseState.key,
            type: type,
          }
        )
        .then((response) => response.data)
        .then((response) => {
          if ((response[year] || [])[`KW${week}`]) {
            const sortedData: { filename: string; date: string }[] = response[
              year
            ][`KW${week}`].sort(
              (
                a: { filename: string; date: string },
                b: { filename: string; date: string }
              ) => {
                if (a.filename < b.filename) return -1;
                if (a.filename > b.filename) return 1;
                return 0;
              }
            );
            const enrichedData = Array.from(sortedData, (file) => {
              return {
                filename: file.filename,
                isNew:
                  monday.isBefore(LocalDate.parse(file.date)) &&
                  week === monday.isoWeekOfWeekyear(),
              };
            });
            setFiles(enrichedData);
          } else {
            setFiles([]);
          }
          setLoading(false);
        });
    }
  }, [type, week, year, baseState.baseURL, baseState.key, baseState.parent]);

  return [files, loading] as const;
}

export default useTypeDocs;
