//https://stackoverflow.com/questions/54738221/typescript-array-find-possibly-undefind
export function ensure<T>(
  argument: T | undefined | null,
  message: string = "This value was promised to be there."
): T {
  if (argument === undefined || argument === null) {
    throw new TypeError(message);
  }

  return argument;
}

//https://stackoverflow.com/questions/36721830/convert-hsl-to-rgb-and-hex
export const hsl2rgb = (h: number, s: number, l: number) => {
  let a = s * Math.min(l, 1 - l);
  let f = (n: number, k = (n + h / 30) % 12) =>
    l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
  return (
    "#" +
    [f(0), f(8), f(4)]
      .map((x) =>
        Math.round(x * 255)
          .toString(16)
          .padStart(2, "0")
      )
      .join("")
  );
};

// This function creates a string out of a Date object,
// alternatively with a date difference.
// Example Output: "2020.01.01"
// It is used to generate the link to fetch Meal XMLs.
export const dayXMLString = (
  d: Date | undefined = undefined,
  diff = 0,
  sep = "."
) => {
  if (!d) {
    d = new Date();
  }

  d.setDate(d.getDate() + diff);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [year, month, day].join(sep);
};

// This function gives you a date object of the day
// of the current week, based on a given weekday string
export const getDayOfThisWeek = (
  day:
    | "monday"
    | "tuesday"
    | "wednesday"
    | "thursday"
    | "friday"
    | "saturday"
    | "sunday"
) => {
  let d = 0;
  switch (day) {
    case "monday":
      d = 1;
      break;
    case "tuesday":
      d = 2;
      break;
    case "wednesday":
      d = 3;
      break;
    case "thursday":
      d = 4;
      break;
    case "friday":
      d = 5;
      break;
    case "saturday":
      d = 6;
      break;
    case "sunday":
      d = 7;
      break;
    default:
      break;
  }
  let date = new Date();
  let today = date.getDay();
  var weekday;
  if (date.getDay() === d) {
    weekday = date;
  } else {
    weekday = new Date().setDate(date.getDate() - today + d);
  }
  return new Date(weekday);
};

// Removes duplicates from an array
export const unique = (a: any[]) => {
  a = a.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (JSON.stringify(a[i]) === JSON.stringify(a[j])) a.splice(j--, 1);
    }
  }

  return a;
};

export const getWeekNumber = (d = new Date()) => {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  // @ts-ignore
  var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  // Return array of year and week number
  return [d.getUTCFullYear(), weekNo];
};

// https://www.sitepoint.com/sort-an-array-of-objects-in-javascript/
export const compareValues = (key: string, order = "asc") => {
  return function innerSort(a: any, b: any) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
};

export const formatToEuro = (number: number) => {
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  }).format(number);
};
