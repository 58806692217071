// React
import React, { useContext, Suspense } from "react";
import { Switch, Route, Link } from "react-router-dom";

// Antd Elements
import { Layout, Typography, Row, Col, Divider } from "antd";

// Context
import { BaseStateContext } from "./App";

// Components
import Header from "./components/header/header";
import GVCoreApp, {GVCapaApp} from "./components/gvcoreApp";
import AdminNews from "./components/adminNews";
import Loading from "./components/loading";
import CustomApp from "./components/apps/customApp/customApp";
import AdBanner from "./components/adBanner";

// Hooks
import useGVCoreConfig from "./hooks/useGVCoreConfig";
import useGVCoreCategories from "./hooks/useGVCoreCategories";
import useGVCoreApps from "./hooks/useGVCoreApps";
import useCustomApps from "./hooks/useCustomApps";

// Types
import { app, customApp, gvcoreCategory, MainConfig } from "./types";
import { LoadingOverlay } from "./components/loadingOverlay";

// Lazy Components
const GVCount = React.lazy(() => import("./components/apps/gvcount/gvcount"));
const Documents = React.lazy(
  () => import("./components/apps/documents/documents")
);
const Supermarket = React.lazy(
  () => import("./components/apps/supermarket/supermarket")
);
const Config = React.lazy(
  () => import("./components/apps/configuration/configuration")
);
const PastOrders = React.lazy(
  () => import("./components/apps/pastOrders/pastOrders")
);
const AdminPanel = React.lazy(
  () => import("./components/apps/adminPanel/adminPanel")
);
const Menu = React.lazy(() => import("./components/apps/menu/menu"));

// Destructuring
const { Text, Title } = Typography;
const { Content, Footer } = Layout;

// GVCore: Main Application, which wraps all
// available apps and renders them on a dashboard
function GVCore() {
  const { baseState } = useContext(BaseStateContext);
  const [userConfig, setUserConfig, userConfigLoading] = useGVCoreConfig();
  const [apps, appError, getApps, writeApps, appsLoading] = useGVCoreApps();
  const [
    allCustomApps,
    customAppsError,
    getCustomApps,
    writeCustomApps,
    customAppsLoading,
    customApps,
  ] = useCustomApps();
  const [categories, categoriesError, getCategories, categoriesLoading] =
    useGVCoreCategories();
  const gvcare = categories[0];
  const gvconcept = categories[1];
  const gvcondition = categories[2];
  const {
    logoMainVisible,
    logoGVCVisible,
    logoParentVisible,
    logoChildVisible,
    backgroundColor,
    titleBackgroundColor,
    serviceContactHTML,
    titleFontColor,
    titleFontSize,
    customerStringType,
    pdfPlanTyp,
    customerName,
  } = userConfig;

  return appsLoading ||
    categoriesLoading ||
    userConfigLoading ||
    customAppsLoading ? (
    <div className="App">
      <Loading />
    </div>
  ) : (
    <div className="App">
      {categoriesError !== "" || appError !== "" || customAppsError !== "" ? (
        <React.Fragment>
          <div>{categoriesError}</div>
          <div>{appError}</div>
          <div>{customAppsError}</div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <React.Fragment>
            <LoadingOverlay isLoading={baseState.isLoading} />
            <Layout className="layout" style={{minHeight: "100vh"}}>
              <Header
                headerColor={titleBackgroundColor}
                serviceString={serviceContactHTML}
                titleColor={titleFontColor}
                titleSize={titleFontSize}
                mainLogoVisible={logoMainVisible}
                parentLogoVisible={logoParentVisible}
                childLogoVisible={logoChildVisible}
                userSelectionDropdownContent={customerStringType}
                pdfPlanTyp={pdfPlanTyp}
                apps={apps.concat(customApps)}
                customerName={customerName}
              />

              <Content
                style={{
                  backgroundColor: backgroundColor ? backgroundColor : "",
                }}
              >
                <div
                  className="site-layout-content"
                  style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    padding: "10px",
                  }}
                >
                  <Switch>
                    <Route exact path="/gvcore">
                      <Row gutter={16} justify="space-around">
                        {/* gvcare */}
                        <Col span={6}>
                          <Category
                            categoryData={gvcare}
                            userConfig={userConfig}
                            apps={apps}
                            customApps={customApps}
                          ></Category>
                        </Col>

                        {/* gvconcept */}
                        <Col span={6}>
                          <Category
                            categoryData={gvconcept}
                            userConfig={userConfig}
                            apps={apps}
                            customApps={customApps}
                          ></Category>
                        </Col>

                        {/* gvcondition */}
                        <Col span={6}>
                          <Category
                            categoryData={gvcondition}
                            userConfig={userConfig}
                            apps={apps}
                            customApps={customApps}
                          ></Category>
                        </Col>
                      </Row>
                    </Route>

                    {/* Routes for default apps */}
                    <Route exact path="/gvcore/gvcount">
                      <Suspense fallback={<div></div>}>
                        <GVCount />
                      </Suspense>
                    </Route>
                    <Route exact path="/gvcore/menu">
                      <Suspense fallback={<div></div>}>
                        <Menu />
                      </Suspense>
                    </Route>
                    <Route exact path="/gvcore/config">
                      <Suspense fallback={<div></div>}>
                        <Config />
                      </Suspense>
                    </Route>
                    <Route exact path="/gvcore/documentsW">
                      <Suspense fallback={<div></div>}>
                        <Documents type="W" />
                      </Suspense>
                    </Route>
                    <Route exact path="/gvcore/documentsS">
                      <Suspense fallback={<div></div>}>
                        <Documents type="S" />
                      </Suspense>
                    </Route>
                    <Route exact path="/gvcore/documentsQ">
                      <Suspense fallback={<div></div>}>
                        <Documents type="Q" />
                      </Suspense>
                    </Route>
                    <Route exact path="/gvcore/pastOrders">
                      <Suspense fallback={<div></div>}>
                        <PastOrders
                          mainLogo={`${
                            baseState.baseURL
                          }img/LOGO${baseState.parent.substring(0, 1)}0000.jpg`}
                        />
                      </Suspense>
                    </Route>
                    <Route exact path="/gvcore/supermarket">
                      <Suspense fallback={<div></div>}>
                        <Supermarket
                          datum=""
                          standalone={true}
                          getSupermarketOrders={() => {}}
                        />
                      </Suspense>
                    </Route>
                    <Route exact path="/gvcore/admin">
                      <Suspense fallback={<div></div>}>
                        <AdminPanel />
                      </Suspense>
                    </Route>

                    {/* Routes for custom apps */}
                    {customApps.map((app) => (
                      <Route exact path={`/gvcore/${app.id}`}>
                        <Suspense fallback={<div></div>}>
                          <CustomApp
                            url={app.prop}
                            getParameter={app.getParameter}
                          />
                        </Suspense>
                      </Route>
                    ))}
                  </Switch>
                </div>
              </Content>
              <Footer style={{ textAlign: "center" }}>
                {(logoGVCVisible || baseState.isAdmin) &&
                  (baseState.isAdmin ? (
                    <Link to={`/gvcore/admin`}>
                      <img
                        alt="gvcoreicon"
                        style={{ height: "50px" }}
                        src={`/images/GVClogos/gvclogo.jpg`}
                      />
                    </Link>
                  ) : (
                    <img
                      alt=""
                      style={{ height: "50px" }}
                      src={`/images/GVClogos/gvclogo.jpg`}
                    />
                  ))}
                <br />
                <Text type="secondary">{`v${process.env.REACT_APP_VERSION}`}</Text>
              </Footer>
            </Layout>
          </React.Fragment>
          {baseState.isLoggedIn && <AdminNews />}
        </React.Fragment>
      )}
    </div>
  );
}

function Category(props: {
  categoryData: gvcoreCategory;
  userConfig: MainConfig;
  apps: app[];
  customApps: customApp[];
}) {
  const { apps, customApps } = props;
  const {
    appGVCountActive,
    appMenuActive,
    appDocsActive,
    appConfigActive,
    appSupermarketActive,
    appPastOrdersActive,
    docsGVCareVisible,
    docsGVConceptVisible,
    docsGVConditionVisible,
  } = props.userConfig;
  const handleActiveApps = (app: string, category: string) => {
    switch (app) {
      case "gvcount":
        return appGVCountActive;
      case "pastOrders":
        return appPastOrdersActive;
      case "supermarket":
        return appSupermarketActive;
      case "config":
        return appConfigActive;
      case "documents":
        switch (category) {
          case "gvcondition":
            return docsGVConceptVisible;
          case "gvcare":
            return docsGVCareVisible;
          case "gvconcept":
            return docsGVConditionVisible;
          default:
            break;
        }
        return appDocsActive;
      case "menu":
        return appMenuActive;
      default:
        return true;
    }
  };
  const { categoryData } = props;
  let id: "S" | "Q" | "W";
  switch (categoryData.id) {
    case "gvcare":
      id = "S";
      break;
    case "gvconcept":
      id = "Q";
      break;
    case "gvcondition":
      id = "W";
  }

  let renderData: any[] = [];

    categoryData.apps.forEach((app) => {
        if (!handleActiveApps(app, categoryData.id)) {
            return null;
        }
        const appData = apps.concat(customApps).find((a) => a.id === app);
        if (!appData) {
            return null;
        } else {
            let route = app === "documents" ? `documents${id}` : app;
            route += `?category=${id}`;
            renderData.push(
                <Col span={8} key={`${categoryData.id}${app}`}>
                    <Link to={`/gvcore/${route}`}>
                        {
                            appData.id === "gvcapa" ?
                                <GVCapaApp
                                    title={appData.title}
                                    description={appData.description}
                                    img={`/images/GVClipCore/${categoryData.label}_Pikto_${appData.imgName}.png`}
                                    category={id}/> :
                                <GVCoreApp
                                    title={appData.title}
                                    description={appData.description}
                                    img={`/images/GVClipCore/${categoryData.label}_Pikto_${appData.imgName}.png`}
                                />
                        }
                    </Link>
                </Col>
            );
        }
    });

  return (
    <React.Fragment>
      <Row justify="space-around">
        <Col>
          <img alt={categoryData.id} src={categoryData.icon} width="60" />
        </Col>
      </Row>
      <Row justify="space-around">
        <Col>
          <Title level={2} style={{ marginBottom: 0 }}>
            {categoryData.displayName}
          </Title>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[16, 16]} justify="start">
        {renderData.length === 0 && (
          <Col span={24}>
            <AdBanner type={id} rotationTime={categoryData.adRotationTime} />
          </Col>
        )}
        {renderData.map((app) => app)}
      </Row>
    </React.Fragment>
  );
}
export default GVCore;
