import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {BaseStateContext} from "../App";


export interface GvCapaOpenTask {
    count: number,
    outOfTime:boolean,
}

export interface GvCapaOpenTasksData {
    sicherheit?: GvCapaOpenTask,
    qualitaet?: GvCapaOpenTask,
    wirtschaftlichkeit?: GvCapaOpenTask,
}

export default function useGvCapaOpenTasks() {
    const [gvCapaOpenTasksData, setGvCapaOpenTasksData] = useState<GvCapaOpenTasksData>();

    const {baseState} = useContext(BaseStateContext);

    useEffect(() => {
        axios
            .post(`https://${baseState.server}/api/server/apis/gvcapa/countOpenGvcapa.php`, {
                tan: baseState.tan,
                dbname: baseState.dbname,
                user: baseState.orderingUser,
            })
            .then((response) => response.data)
            .then((data: GvCapaOpenTasksData) => {
                setGvCapaOpenTasksData(data);
            });
    }, [baseState.baseURL, baseState.parent]);

    return gvCapaOpenTasksData;
}
