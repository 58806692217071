// React
import React, { FC, useState, useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router";

// UI Components
import {
  PageHeader,
  Space,
  Popover,
  Typography,
  Dropdown,
  Menu,
  message,
  Button,
  Tooltip,
  Badge,
} from "antd";

// Icons
import {
  PhoneOutlined,
  DownOutlined,
  SmileOutlined,
  BugOutlined,
  ArrowLeftOutlined,
  LogoutOutlined,
  FilePdfOutlined,
  ExclamationCircleTwoTone,
  InfoOutlined,
} from "@ant-design/icons";

// Components
import TicketModal from "./ticketModal";
import FeedbackModal from "./feedbackModal";
import DocumentsModal from "./documentsModal";

// Hooks
import useLogin from "../../hooks/useLogin";

// Third Party Libraries
import Interweave from "interweave";
import axios from "axios";
import xml2js from "xml2js";

// Context
import { BaseStateContext } from "../../App";

// Types
import { ServerConfigResponse, hdProps } from "../../types";

// Helper Functions
import { ensure } from "../../helpers";
import GVCoreApp from "../gvcoreApp";
import {Link} from "react-router-dom";

// Destructuring
const { Title, Text } = Typography;
const { SubMenu } = Menu;

// Header: The Main Page Header
// It renders basic stuff like logos
// and gives access to functions like
// selecting the current user, sending
// feedback and so on
const Header: FC<hdProps> = ({
  headerColor = "",
  titleColor = "",
  titleSize = "20",
  mainLogoVisible = false,
  parentLogoVisible = false,
  childLogoVisible = false,
  serviceString = "",
  userSelectionDropdownContent = 1,
  pdfPlanTyp = "",
  apps = [],
  customerName = "",
}) => {
  const { baseState, updateBaseState } = useContext(BaseStateContext);
  const [userSelectionMenu, setUserSelectionMenu] = useState(<Menu></Menu>);
  const [ticketModalVisible, toggleTicketModal] = useState(false);
  const [feedbackModalVisible, toggleFeedbackModal] = useState(false);
  const [documentsModalVisible, toggleDocumentsModal] = useState(false);
  const [thereIsANewFile, setThereIsANewFile] = useState(false);
  const [userName, setUserName] = useState(customerName);

  const location = useLocation();
  const history = useHistory();

  // The useLogin hook includes the logout method,
  // which is used by the header
  const login = useLogin();

  // This method is executed when a new user is selected
  // in the user selection dropdown menu
  const selectNewOrderingUser = (
    e: any,
    displayString: string,
    userName: string
  ) => {
    // Render an notification about the selected user
    message.info(`Gewählter Kunde: ${displayString}`);

    // Change the displayed user in the header
    setUserName(userName);
    // Change the selected user in the base state
    updateBaseState({
      type: "CHANGEORDERINGUSER",
      orderingUser: e.key,
      orderingUserName: userName || "",
      orderingUserClass:
        e.key.substr(2, 3) === "000"
          ? "parent"
          : e.key.length === 5
          ? "child"
          : "grandchild",
    });
  };

  // Set the headers title according to
  // the current opened app

  /**
   * Dynamic Title which is rendered in the
   * center of the page header
   */
  let title = "";
  switch (location.pathname) {
    case "/gvcore":
    case "/gvcore/":
      title = "GVCore";
      break;
    case "/gvcore/documentsW":
    case "/gvcore/documentsS":
    case "/gvcore/documentsQ":
      title = "Dokumente";
      break;
    case "/gvcore/admin":
      title = "Admin Panel";
      break;
    default:
      title = ensure(
        apps.find((app) => `/gvcore/${app.id}` === location.pathname)
      ).title;
  }

  const hasChefuebersichtApp = apps.some((app) => app.id === 'chefuebersicht');

  /**
   * Boolean indicator if the current
   * location is the root route
   */
  const locationIsNotRoot =
    location.pathname.split("/").filter((string) => string).length > 1;

  // Create the content of the user selection dropdown
  useEffect(() => {
    if (baseState.isLoggedIn) {
      axios
        .get(
          `${baseState.baseURL}${
            baseState.parent
          }/GVCore.xml?timestamp=${new Date().getTime()}`
        )
        .then((response) =>
          xml2js
            .parseStringPromise(response.data)
            .then((response) => response.dataroot)
        )
        .then((config: ServerConfigResponse) => {
          // Create the user selection menu for logged in parent users
          if (baseState.loggedInUser.substr(2, 3) === "000") {
            let parentString = createDropdownContent(
              config,
              userSelectionDropdownContent
            );
            setUserSelectionMenu(
              <Menu>
                <Menu.Item
                  key={config.id_benutzer[0]}
                  onClick={(e) =>
                    selectNewOrderingUser(
                      e,
                      parentString,
                      (config.customerName || "")[0]
                    )
                  }
                >
                  {parentString}
                </Menu.Item>
                {(config.kind || []).map((kind) => {
                  const childConfig = kind;
                  let childString = createDropdownContent(
                    childConfig,
                    userSelectionDropdownContent
                  );
                  return kind.enkel ? (
                    <SubMenu
                      key={kind.id_benutzer[0]}
                      onTitleClick={(e) =>
                        selectNewOrderingUser(
                          e,
                          childString,
                          (childConfig.customerName || "")[0]
                        )
                      }
                      title={childString}
                    >
                      {kind.enkel.map((enkel) => {
                        const grandchildConfig = enkel;
                        let grandchildString = createDropdownContent(
                          grandchildConfig,
                          userSelectionDropdownContent
                        );
                        return (
                          <Menu.Item
                            key={enkel.id_benutzer[0]}
                            onClick={(e) =>
                              selectNewOrderingUser(
                                e,
                                grandchildString,
                                (grandchildConfig.customerName || "")[0]
                              )
                            }
                          >
                            {grandchildString}
                          </Menu.Item>
                        );
                      })}
                    </SubMenu>
                  ) : (
                    <Menu.Item
                      key={kind.id_benutzer[0]}
                      onClick={(e) =>
                        selectNewOrderingUser(
                          e,
                          childString,
                          (childConfig.customerName || "")[0]
                        )
                      }
                    >
                      {childString}
                    </Menu.Item>
                  );
                })}
              </Menu>
            );
          } else if (baseState.loggedInUser.length === 5) {
            // Create the user selection menu for logged in child users
            const childConfig = (config.kind || []).find(
              (kind) => kind.id_benutzer[0] === baseState.loggedInUser
            );

            if (childConfig) {
              let childString = createDropdownContent(
                childConfig,
                userSelectionDropdownContent
              );
              setUserSelectionMenu(
                <Menu>
                  <Menu.Item
                    key={childConfig.id_benutzer[0]}
                    onClick={(e) =>
                      selectNewOrderingUser(
                        e,
                        childString,
                        (childConfig.customerName || "")[0]
                      )
                    }
                  >
                    {childString}
                  </Menu.Item>
                  {childConfig.enkel &&
                    childConfig.enkel.map((enkel) => {
                      const grandchildConfig = enkel;

                      let grandchildString = createDropdownContent(
                        grandchildConfig,
                        userSelectionDropdownContent
                      );
                      return (
                        <Menu.Item
                          key={enkel.id_benutzer[0]}
                          onClick={(e) =>
                            selectNewOrderingUser(
                              e,
                              grandchildString,
                              (grandchildConfig.customerName || "")[0]
                            )
                          }
                        >
                          {grandchildString}
                        </Menu.Item>
                      );
                    })}
                </Menu>
              );
            }
          } else if (baseState.loggedInUser.length === 11) {
            // Create the user selection menu for logged in grandchild users
            setUserSelectionMenu(
              <Menu
                onClick={(e) =>
                  selectNewOrderingUser(
                    e,
                    baseState.loggedInUser,
                    (config.customerName || "")[0]
                  )
                }
              >
                <Menu.Item key={baseState.loggedInUser}>
                  {baseState.loggedInUser}
                </Menu.Item>
              </Menu>
            );
          }
        });
    }
  }, [
    userSelectionDropdownContent,
    baseState.baseURL,
    baseState.isLoggedIn,
    baseState.loggedInUser,
    baseState.parent,
  ]);

  // Return the render string for a user, depending
  // on the given render type
  const createDropdownContent = (
    config: ServerConfigResponse,
    type: number
  ) => {
    const kname = (config.customerName || [])[0] || "";
    switch (type) {
      case 1:
        return config.id_benutzer[0];
      case 2:
        return kname;
      case 3:
        return `${config.id_benutzer[0]} ${kname}`;
      default:
        return config.id_benutzer[0];
    }
  };

  return baseState.isLoggedIn ? (
    <PageHeader
      className="header"
      style={{
        backgroundColor: headerColor ? headerColor : "",
        minHeight: "62px",
        boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.2)",
      }}
      ghost={false}
      backIcon={false}
      title={
        <React.Fragment>
          <Space>
            {locationIsNotRoot && (
              <ArrowLeftOutlined
                className="clickable"
                onClick={() => history.push("/gvcore")}
              />
            )}
            {mainLogoVisible && (
              <img
                onClick={() => locationIsNotRoot && history.push("/gvcore")}
                className="clickable"
                alt=""
                style={{ height: "50px" }}
                src={`${baseState.baseURL}img/LOGO${baseState.parent.substring(
                  0,
                  1
                )}0000.jpg`}
              />
            )}
          </Space>
          <Space>
            <Popover
              content={<Interweave content={serviceString}></Interweave>}
              title="Servicekontakt"
            >
              <PhoneOutlined style={{ paddingLeft: "10px", color: "grey" }} />
            </Popover>
          </Space>
        </React.Fragment>
      }
      extra={[
        hasChefuebersichtApp && (
            <Link to={`/gvcore/chefuebersicht`}>
              <GVCoreApp
                  title={''}
                  description={'Chefübersicht'}
                  img={`/images/GVClipCore/GVC_Pikto_Chefuebersicht.png`}
                  size={1}/>
            </Link>),
        parentLogoVisible && (
          <img
            key="parentLogo"
            alt=""
            style={{ height: "50px" }}
            src={`${baseState.baseURL}img/LOGO${baseState.parent}.jpg`}
          />
        ),
        childLogoVisible && baseState.loggedInUserClass === "child" && (
          <img
            key="childLogo"
            alt=""
            style={{ height: "50px" }}
            src={`${baseState.baseURL}img/LOGO${baseState.orderingUser}.jpg`}
          />
        ),
        <Text type="secondary" key={"orderinguser"}>
          {userName}
        </Text>,
        <Dropdown
          disabled={!baseState.isLoggedIn}
          key="userSelection"
          overlay={userSelectionMenu}
          placement="bottomCenter"
          arrow
        >
          <Button>
            {baseState.orderingUser}
            <DownOutlined />
          </Button>
        </Dropdown>,
        <Tooltip title="Dokumente" key="documents">
          {thereIsANewFile ? (
            <Badge count={<ExclamationCircleTwoTone twoToneColor="#ff7875" />}>
              <Button
                type="default"
                onClick={() => toggleDocumentsModal(true)}
                shape="circle"
                style={{ marginTop: "-5px" }}
                icon={<FilePdfOutlined />}
              />
            </Badge>
          ) : (
            <Button
              type="default"
              onClick={() => toggleDocumentsModal(true)}
              shape="circle"
              icon={<FilePdfOutlined />}
            />
          )}
        </Tooltip>,
        <Tooltip title="Fehler melden" key="bugs">
          <Button
            type="default"
            onClick={() => toggleTicketModal(true)}
            shape="circle"
            icon={<BugOutlined />}
          />
        </Tooltip>,
        <Tooltip title="Feedback senden" key="feedback">
          <Button
            type="default"
            onClick={() => toggleFeedbackModal(true)}
            shape="circle"
            icon={<SmileOutlined />}
          />
        </Tooltip>,
        <Tooltip title="Ausloggen" key="logout">
          <Button
            type="default"
            onClick={() => {
              locationIsNotRoot && history.push("/gvcore");
              login[2]();
            }}
            shape="circle"
            icon={<LogoutOutlined />}
          />
        </Tooltip>,
      ]}
    >
      <div key="title" style={{ marginTop: "-50px" }}>
        <Title
          style={{
            marginBottom: 0,
            color: titleColor || "",
            fontSize: `${titleSize}pt` || "",
          }}
          level={4}
        >
          {title}
        </Title>
      </div>
      {ticketModalVisible && (
        <TicketModal
          appversion={process.env.REACT_APP_VERSION}
          modal={ticketModalVisible}
          setModal={toggleTicketModal}
        />
      )}
      {feedbackModalVisible && (
        <FeedbackModal
          modal={feedbackModalVisible}
          setModal={toggleFeedbackModal}
        />
      )}
      <DocumentsModal
        modal={documentsModalVisible}
        setModal={toggleDocumentsModal}
        pdfPlanTyp={pdfPlanTyp}
        setThereIsANewFile={setThereIsANewFile}
      />
    </PageHeader>
  ) : null;
};

export default Header;
