import React, { useContext, useEffect } from "react";
import { Col, Row, Modal, Typography, List, Tooltip } from "antd";
import {
  DownloadOutlined,
  LeftOutlined,
  RightOutlined,
  ExclamationCircleTwoTone,
} from "@ant-design/icons";

import useKWStepper from "../../hooks/useKWStepper";
import useTypeDocs from "../../hooks/useTypeDocs";
import { BaseStateContext } from "../../App";
const { Title, Text } = Typography;

function DocumentsModal(props: {
  modal: boolean;
  setModal: Function;
  pdfPlanTyp: string;
  setThereIsANewFile: Function;
}) {
  const { modal, setModal, pdfPlanTyp, setThereIsANewFile } = props;
  const { baseState } = useContext(BaseStateContext);
  const [selectedKW, selectedYear, goToNextKW, goToLastKW] = useKWStepper();
  const [files, loading] = useTypeDocs(pdfPlanTyp, selectedKW, selectedYear);

  useEffect(() => {
    setThereIsANewFile(false);
    files.forEach((file) => file.isNew && setThereIsANewFile(true));
  }, [files]);

  return (
    <Modal
      width={600}
      visible={modal}
      onCancel={() => {
        setModal(false);
      }}
      footer={null}
    >
      <Row gutter={[16, 16]} justify="space-between">
        <Col>
          <Title level={3}>Dokumente</Title>
        </Col>
        <Col flex="auto" style={{ paddingTop: 7 }}>
          <LeftOutlined className="clickable" onClick={() => goToLastKW()} />

          <Text type="secondary">{`KW ${selectedKW}/${selectedYear}`}</Text>

          <RightOutlined className="clickable" onClick={() => goToNextKW()} />
        </Col>
      </Row>
      <Row gutter={[16, 16]} justify="space-between">
        <Col span={24}>
          <List
            locale={{ emptyText: "Keine Dokumente verfügbar" }}
            size="small"
            className="demo-loadmore-list"
            itemLayout="horizontal"
            dataSource={files}
            loading={loading}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <a
                    className="clickable"
                    href={`${baseState.baseURL}${baseState.parent}/GVCountPDF/${selectedYear}/KW${selectedKW}/${item.filename}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <DownloadOutlined />
                    {` Download`}
                  </a>,
                ]}
              >
                {item.isNew ? (
                  <Tooltip title="Neues Dokument">
                    <ExclamationCircleTwoTone
                      twoToneColor="#ff7875"
                      style={{ marginRight: 6 }}
                    />
                  </Tooltip>
                ) : (
                  <span style={{ marginLeft: 20 }} />
                )}
                {item.filename}
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </Modal>
  );
}

export default DocumentsModal;
