import React, { useContext } from "react";
import { Carousel, Image } from "antd";
import useAdImages from "../hooks/useAdImages";
import { BaseStateContext } from "../App";

function AdBanner(props: { type: "Q" | "S" | "W"; rotationTime: number }) {
  const { baseState } = useContext(BaseStateContext);
  const [adImages, adImagesLoading] = useAdImages();
  const filteredImages = adImages.filter(
    (image) => image.split("-")[1] === props.type
  );
  return (
    <Carousel autoplaySpeed={props.rotationTime} autoplay dots={false}>
      {filteredImages.map((image) => (
        <Image
          preview={false}
          key={image}
          width="100%"
          src={`${baseState.gvcoreConfigUrl}/pic/${image}`}
        />
      ))}
    </Carousel>
  );
}
export default AdBanner;
