import { useState, useEffect, useContext } from "react";
import { BaseStateContext } from "../App";
import { newsItem } from "../types";
import axios from "axios";

function useAdminNews() {
  const { baseState } = useContext(BaseStateContext);
  const [newsItems, setNewsItems] = useState<newsItem[]>([]);
  useEffect(() => {
    axios
      .get(
        `${baseState.gvcoreConfigUrl}/news.json?time=${new Date()}`
      )
      .then((response) => setNewsItems(response.data));
  }, [baseState.server]);

  return newsItems;
}

export default useAdminNews;
