import { useState, useEffect, useContext } from "react";
import { BaseStateContext } from "../App";
import { gvcoreCategory } from "../types";
import axios from "axios";

function useGVCoreCategories() {
  const { baseState } = useContext(BaseStateContext);
  const [categories, setCategories] = useState<gvcoreCategory[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const getCategories = () => {
    setLoading(true);
    Promise.all(
      ["gvcare", "gvconcept", "gvcondition"].map((category) => {
        return axios.get(
          `${baseState.gvcoreConfigUrl}/${category}.json?time=${new Date()}`
        );
      })
    )
      .then((responses) => {
        setCategories([
          responses[0].data,
          responses[1].data,
          responses[2].data,
        ]);
        setLoading(false);
      })
      .catch(() =>
        setError("GVCore Konfiguration konnte nicht geladen werden")
      );
  };

  useEffect(() => {
    getCategories();
  }, [baseState.baseURL, baseState.parent, baseState.isLoggedIn]);
  return [categories, error, getCategories, loading] as const;
}

export default useGVCoreCategories;
