import { useState, useEffect, useContext } from "react";
import { BaseStateContext } from "../App";
import { customApp } from "../types";
import axios from "axios";
import { message } from "antd";

function useCustomApps() {
  const { baseState } = useContext(BaseStateContext);
  const [apps, setApps] = useState<customApp[]>([]);
  const [visibleApps, setVisibleApps] = useState<customApp[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const getApps = () => {
    setLoading(true);
    axios
        .get(
            `${baseState.gvcoreConfigUrl}/customApps.json?time=${new Date()}`
        )
        .then((response) => {
          setApps(response.data);
          let filteredApps = response.data.filter((app: customApp) => !app.permCanCreateTan || (app.permCanCreateTan && baseState.permCanCreateTan));
          setVisibleApps(filteredApps);
          setLoading(false);
        })
        .catch(() => setError("GVCore Custom Apps konnten nicht geladen werden"));
  };

  const writeApps = (apps: customApp[]) => {
    axios
      .post(
        `https://${baseState.server}/api/server/apis/updateCustomApps.php`,
        {
          key: baseState.key,
          value: JSON.stringify(apps),
          authfactor: `${baseState.authfactor.toUpperCase()}`,
          parent: baseState.parent,
        }
      )
      .then((response) => {
        if (response.data.code === 200) {
          message.success(`Anwendungen gespeichert!`);
        } else {
          message.error("Es ist ein Fehler aufgetreten");
        }
      })
      .catch(() => message.error("Es ist ein Fehler aufgetreten"));
  };

  useEffect(() => {
    getApps();
  }, [baseState.server]);

  return [apps, error, getApps, writeApps, loading, visibleApps] as const;
}

export default useCustomApps;
