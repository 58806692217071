import { useState, useContext } from "react";
import { message } from "antd";
import axios from "axios";
import { BaseStateContext } from "../App";

const useLogin = () => {
  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const { baseState, updateBaseState } = useContext(BaseStateContext);
  // Set user from url parameter
  // Authentification
  const logout = () => {
    updateBaseState({
      type: "LOGOUT",
    });
  };
  const login = (authfactor: string, tan: string) => {
    setIsLoggingIn(true);
    axios
      .post(`https://${baseState.server}/api/server/apis/login.php`, {
        dbname: authfactor,
        key: baseState.key,
        tan: tan,
      })
      .then((response) => response.data.data[0])
      .then((response) => {
        // Check if the tan is expired or not
        const tmpDate = new Date(response.expires.substr(0, 10));
        if (tmpDate >= today) {
          setIsLoggingIn(false);
          updateBaseState({
            type: "LOGIN",
            baseURL: `https://${
              baseState.server
            }/${authfactor.toUpperCase()}-A/`,
            gvcoreConfigUrl: `https://${
              baseState.server
            }/${authfactor.toUpperCase()}-A/${
              response.owner.substr(0, 2) + "000"
            }/gvcore_config`,
            authfactor: `${authfactor.toUpperCase()}-A`,
            loggedInUser: response.owner,
            orderingUser: response.owner,
            isAdmin: response.gvcoreAdmin === 1,
            loggedInUserClass:
              response.owner.substr(2, 3) === "000"
                ? "parent"
                : response.owner.length === 5
                ? "child"
                : "grandchild",
            orderingUserClass:
              response.owner.substr(2, 3) === "000"
                ? "parent"
                : response.owner.length === 5
                ? "child"
                : "grandchild",
            isLoggedIn: true,
            parent: response.owner.substr(0, 2) + "000",
            dbname: authfactor,
            tan: tan,
            permCanCreateTan: response.permCanCreateTan === 1,
          });
        } else {
          setIsLoggingIn(false);
          message.error("TAN abgelaufen");
        }
        return response;
      })
      .then((response) => {
        let searchBase: string = "";
        let userClass =
          response.owner.substr(2, 3) === "000"
            ? "parent"
            : response.owner.length === 5
            ? "child"
            : "grandchild";
        switch (userClass) {
          case "parent":
            searchBase = response.owner.substr(0, 2);
            break;
          case "child":
            searchBase = response.owner;
            break;
          case "grandchild":
            searchBase = response.owner;
            break;
          default:
            searchBase = response.owner;
            break;
        }
        axios.post(
          `https://${baseState.server}/api/server/apis/userNames.php`,
          {
            dbname: authfactor,
            key: baseState.key,
            searchBase: searchBase,
          }
        );
      })
      .catch((error) => {
        setIsLoggingIn(false);
        message.error(
          "Authentifizierungsproblem. Bitte prüfen Sie ihre Zugangsdaten."
        );
      });
  };

  return [isLoggingIn, login, logout] as const;
};

export default useLogin;
