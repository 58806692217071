// React
import React, { useState, useContext, useEffect } from "react";

// UI Components
import { Row, Col, Form, Input, Button, Typography, Card, Spin } from "antd";

// Icons
import { LoadingOutlined } from "@ant-design/icons";

// Hooks
import useLogin from "../hooks/useLogin";
import useDirectLink from "../hooks/useDirectLink";
import { useHistory } from "react-router-dom";

// Context
import { BaseStateContext } from "../App";

// Destructuring
const { Title, Text } = Typography;

// Login: Login component which allows users
// to authenticate themself to use GVCore
export default function Login() {
  const [isLoggingIn, login] = useLogin();
  const history = useHistory();
  const directLink = useDirectLink();
  const [triedDirectLink, setTriedDirectLink] = useState(false);
  const { baseState } = useContext(BaseStateContext);

  // If a direct link has been used, try to login with it
  useEffect(() => {
    if (
      !baseState.isLoggedIn &&
      directLink !== false &&
      !isLoggingIn &&
      !triedDirectLink
    ) {
      login(directLink[1], directLink[2]);
      setTriedDirectLink(true);
      //history.push("/gvcore");
    }
  }, [directLink, isLoggingIn, history, triedDirectLink, baseState.isLoggedIn]);

  // Method is executed after user uses login button in
  // login form
  const loginWithCredentials = (credentials: any) => {
    login(
      credentials.authfactor.split("-")[0].toLowerCase(),
      credentials.securitykey
        ? credentials.tan + credentials.securitykey
        : credentials.tan
    );
  };

  return (
    <div
      className="loginScreen"
      style={{
        backgroundImage: `url(https://${baseState.server}/background.jpg)`,
      }}
    >
      <Row justify="space-around" align="middle" style={{ height: "100%" }}>
        <Col>
          <Card className="loginCard">
            <Row
              justify="space-around"
              align="middle"
              style={{ marginBottom: 20 }}
            >
              <Col>
                <img
                  alt="GVC Logo"
                  style={{ height: "50px" }}
                  src={`/images/GVClogos/gvclogo.jpg`}
                />
              </Col>
            </Row>
            <Row justify="space-around" style={{ marginBottom: 30 }}>
              <Col>
                <Title level={3}>GVCore</Title>
              </Col>
            </Row>
            {!isLoggingIn ? (
              <React.Fragment>
                <Form
                  wrapperCol={{ span: 16 }}
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={loginWithCredentials}
                >
                  <Form.Item
                    labelCol={{ span: 8 }}
                    label="TAN"
                    name="tan"
                    rules={[
                      {
                        required: true,
                        message: "Bitte geben Sie Ihre TAN ein",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    labelCol={{ span: 8 }}
                    label="Sicherheitsschlüssel"
                    name="securitykey"
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item
                    labelCol={{ span: 8 }}
                    label="Authentifizierungsfaktor"
                    name="authfactor"
                    rules={[
                      {
                        required: true,
                        message:
                          "Bitte geben Sie Ihren Authentifizierungsfaktor an",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    wrapperCol={{ span: 24, style: { textAlign: "center" } }}
                  >
                    <Button type="primary" htmlType="submit">
                      Anmelden
                    </Button>
                  </Form.Item>
                </Form>
                <Row justify="center">
                  <Col>
                    <Text type="secondary">{`v${process.env.REACT_APP_VERSION}`}</Text>
                  </Col>
                </Row>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Row justify="center">
                  <Col>
                    <Spin
                      indicator={
                        <LoadingOutlined style={{ fontSize: 40 }} spin />
                      }
                    />
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
}
