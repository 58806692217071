// React
import React from "react";

// UI Components
import {Badge, Col, Row, Tooltip, Typography} from "antd";
import useGvCapaOpenTasks from "../hooks/useGvCapaOpenTasks";

// Destructuring
const {Text} = Typography;

interface GVCoreAppProps {
    title: string;
    description: string;
    img: string;
    size?: number;
}

// GVCoreApp: App Icon which is
// rendered on the GVCore Dashboard
const GVCoreApp = (props: GVCoreAppProps) => {
    const {title, description, img, size = 2} = props;
    return (
        <React.Fragment>
            <Tooltip title={description}>
                <Row justify="space-around">
                    <Col>
                        <div
                            style={{
                                backgroundImage: `url(${img})`,
                                backgroundSize: "cover",
                                padding: size + "rem",
                            }}
                            className="gvcApp absolute-center"
                        >
                            <Text type="secondary"></Text>
                        </div>
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col>
                        <Text>{title}</Text>
                    </Col>
                </Row>
            </Tooltip>
        </React.Fragment>
    );
};

export default GVCoreApp;


interface GVCapaApp extends GVCoreAppProps {
    category: string;
}

export function GVCapaApp(props: GVCapaApp) {
    const {category, title, description, img, size = 2} = props;

    const gvCapaOpenTasks = useGvCapaOpenTasks();


    const gvCapaOpenTask = getCountByCategory();
    const count = gvCapaOpenTask?.count ?? 0;
    const color = gvCapaOpenTask?.outOfTime ? "red" : "rgb(0,0,0)";

    function getCountByCategory() {
        switch (category) {
            case "S":
                return gvCapaOpenTasks?.sicherheit;
            case "Q":
                return gvCapaOpenTasks?.qualitaet;
            case "W":
                return gvCapaOpenTasks?.wirtschaftlichkeit;
            default:
                return undefined;
        }
    }

    return (
        <Badge count={count} overflowCount={99} showZero={false} title={'Offene GVCapa Vorgänge'} offset={[0, 60]}
               color={color}>
            <GVCoreApp title={title} description={description} img={img} size={size}/>
        </Badge>
    );
}