import { useState, useEffect, useContext } from "react";
import { BaseStateContext } from "../App";
import { app } from "../types";
import axios from "axios";
import { message } from "antd";

function useGVCoreApps() {
  const { baseState } = useContext(BaseStateContext);
  const [apps, setApps] = useState<app[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const getApps = () => {
    setLoading(true);
    axios
      .get(
        `${baseState.gvcoreConfigUrl}/apps.json?time=${new Date()}`
      )
      .then((response) => {
        setApps(response.data);
        setLoading(false);
      })
      .catch(() => setError("GVCore Apps konnte nicht geladen werden"));
  };

  const writeApps = (apps: app[]) => {
    axios
      .post(
        `https://${baseState.server}/api/server/apis/updateGVCoreApps.php`,
        {
          key: baseState.key,
          value: JSON.stringify(apps),
          authfactor: `${baseState.authfactor.toUpperCase()}`,
          parent: baseState.parent,
        }
      )
      .then((response) => {
        if (response.data.code === 200) {
          message.success(`Anwendungen gespeichert!`);
        } else {
          message.error("Es ist ein Fehler aufgetreten");
        }
      })
      .catch(() => message.error("Es ist ein Fehler aufgetreten"));
  };

  useEffect(() => {
    getApps();
  }, [baseState.server]);

  return [apps, error, getApps, writeApps, loading] as const;
}

export default useGVCoreApps;
