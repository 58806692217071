import React, { Component } from "react";
import "./App.css";
import { ReloadOutlined } from "@ant-design/icons";

class ErrorBoundary extends Component<{},{hasError:boolean}> {
  constructor(props:any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error:any, info:any) {
    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <React.Fragment>
          <div
            className="absolute-center"
            style={{
              paddingTop: "100px ",
            }}
          >
            <h1>Etwas ist schiefgelaufen :(</h1>
          </div>
          <div
            onClick={() => window.location.reload()}
            className="absolute-center"
            style={{
              paddingTop: "10px ",
              cursor: "pointer",
            }}
          >
            <ReloadOutlined style={{ fontSize: "50pt" }} />
          </div>
        </React.Fragment>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
