import { LocalDate } from "@js-joda/core";
import { useState } from "react";

function useKWStepper() {
  const d = LocalDate.now();
  const [date, setDate] = useState(d);
  const next = () => {
    setDate(date.plusWeeks(1));
  };
  const last = () => {
    setDate(date.minusWeeks(1));
  };
  return [date.isoWeekOfWeekyear(), date.isoWeekyear(), next, last] as const;
}

export default useKWStepper;
